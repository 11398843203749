'use client';

import { usePathname } from 'next/navigation';
import type { VrmLookupDumbModalProps } from '@/components/molecules/VrmLookupDumbModal';
import { VrmLookupDumbModal } from '@/components/molecules/VrmLookupDumbModal';

type GetQuoteCtaProps = VrmLookupDumbModalProps & {
  readonly toggleButtonText: string;
  readonly toggleButtonTextHomepage: string;
};

export function GetQuoteCta({
  children,
  toggleButtonText,
  toggleButtonTextHomepage,
  ...props
}: GetQuoteCtaProps): JSX.Element {
  const pathname = usePathname();

  return (
    <VrmLookupDumbModal
      size="S"
      {...props}
      toggleButtonText={pathname === '/' ? toggleButtonTextHomepage : toggleButtonText}
    >
      {children}
    </VrmLookupDumbModal>
  );
}
