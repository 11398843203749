'use client';

import type { HTMLAttributes } from 'react';
import React, { useRef } from 'react';
import classNames from 'classnames';
import { Icon } from '@/components/atoms/Icon';

import styles from './index.module.scss';

export interface DropDownProps extends Omit<HTMLAttributes<HTMLSpanElement>, 'title'> {
  readonly title: string | [closed: string, opened: string];
}

export function DropDown({ className, title, children, ...props }: DropDownProps): JSX.Element {
  /**
   * The control of the open dropdown menu is done in CSS with `:focus-within`.
   * This is only to know when to remove focus at second click on the toggle element.
   * No need for state to rerender anything.
   */
  const opened = useRef(false);

  return (
    <span
      className={classNames(DropDown.displayName, styles.component, className)}
      onBlur={() => {
        opened.current = false;
      }}
      // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex -- Safari focus-within on non-interactive elements
      tabIndex={0}
      {...props}
    >
      <a
        href="#0"
        className={classNames('dropdown-toggle', styles['dropdown-toggle'])}
        onClick={(e) => {
          e.preventDefault();
          if (opened.current) {
            // remove `:focus-within`
            (document.activeElement as HTMLElement)?.blur();
          } else {
            opened.current = true;
          }
        }}
      >
        <span className="title">
          {typeof title === 'string' ? (
            title
          ) : (
            <>
              <span className={styles['title-closed']}>{title[0]}</span>
              <span className={styles['title-opened']}>{title[1]}</span>
            </>
          )}
        </span>
        <Icon id="arrows/chevron-down" legacy={false} />
      </a>
      <span className={classNames('dropdown-content', styles['dropdown-content'])}>{children}</span>
    </span>
  );
}
DropDown.displayName = 'DropDown';
