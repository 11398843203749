'use client';

import { Icon } from '@/components/atoms/Icon';
import styles from './index.module.scss';

export function NavigationMenuToggle({ id }: { id: string }): JSX.Element {
  return (
    <>
      <input type="checkbox" id={`${id}-toggle`} />
      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
      <label
        htmlFor={`${id}-toggle`}
        className={styles['menu-toggle']}
        onClickCapture={() => {
          /**
           * No scroll on mobile when menu is opened.
           */
          const overlayClassName = 'NavigationMenu-overlay';
          if (document.body.classList.contains(overlayClassName)) {
            document.body.classList.remove(overlayClassName);
          } else {
            document.body.classList.add(overlayClassName);
          }
        }}
      >
        <Icon className={styles.mobileMenu} id="general/menu-01" legacy={false} />
        <Icon className={styles.closeMobileMenu} id="general/x-close" legacy={false} />
      </label>
    </>
  );
}
